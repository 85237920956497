import { SortOrder } from 'antd/es/table/interface';

type Result = 'ASC' | 'DESC';

const adaptSortOrder = (sort: SortOrder): Result => {
  switch (sort) {
    case 'descend': {
      return 'DESC';
    }
    default:
    case 'ascend': {
      return 'ASC';
    }
  }
};

export default adaptSortOrder;
