export enum ComparisonType {
  IS = 'is',
  IS_NOT = 'isNot',
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LOWER_THAN = 'lt',
  LOWER_THAN_OR_EQUAL = 'lte',
  LIKE = 'like',
  I_LIKE = 'iLike',
  NOT_LIKE = 'notLike',
  IN = 'in',
  NOT_IN = 'notIn',
  BETWEEN = 'between',
  NOT_BETWEEN = 'notBetween',
}
