import { useEffect, useRef } from 'react';

/**
 * Work like `componentDidUpdate`
 */
const useUpdateEffect = <T>(callback: () => void, deps: T[]): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return undefined;
    }

    return callback();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
