import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import './ModalLoader.scss';

export interface ModalLoaderProps {
  size?: 'small' | 'large';
}

const ModalLoader = ({ size = 'large' }: ModalLoaderProps): ReactElement => (
  <div className="modal-loader">
    <Spin size={size} />
  </div>
);

export default ModalLoader;
