import React, { FC, PropsWithChildren } from 'react';
import styles from './MaybeRenderEmptyValue.module.scss';

const MaybeRenderEmptyValue: FC<PropsWithChildren> = ({ children }) => {
  return (
    <span className={children ? '' : styles.nullableValue}>
      {children ? children : 'null'}
    </span>
  );
};

export default MaybeRenderEmptyValue;
