export default (currentPage: number, totalCount: number): Array<number | string> => {
  if (totalCount > 7 && currentPage > totalCount - 4) {
    return [1, 's1', totalCount - 4, totalCount - 3, totalCount - 2, totalCount - 1, totalCount];
  }

  if (totalCount > 7 && currentPage > 4) {
    return [1, 's1', currentPage - 1, currentPage, currentPage + 1, 's2', totalCount];
  }

  if (totalCount > 7) {
    return [1, 2, 3, 4, 5, 's1', totalCount];
  }

  return Array.from({ length: totalCount }, (_, i) => i + 1);
};
