export type TruncateStringType = 'start' | 'center' | 'end' | 'default';
export type TruncateStringConfig = {
  type?: TruncateStringType,
  startLength?: number,
  endLength?: number,
};

export const truncateString = (originalString: string, config?: TruncateStringConfig) => {
  const cfg = {
    type: config?.type || 'default',
    startLength: config?.startLength || 4,
    endLength: config?.endLength || 4,
  };

  switch (cfg.type) {
    case 'start':
      if (originalString.length <= cfg.endLength) {
        return originalString;
      } else {
        const endPart = originalString.slice(-cfg.endLength);
        return `...${endPart}`;
      }

    case 'center':
      if (originalString.length <= cfg.startLength + cfg.endLength) {
        return originalString;
      } else {
        const startPart = originalString.slice(0, cfg.startLength);
        const endPart = originalString.slice(-cfg.endLength);
        return `${startPart}...${endPart}`;
      }

    case 'end':
      if (originalString.length <= cfg.startLength) {
        return originalString;
      } else {
        const startPart = originalString.slice(0, cfg.startLength);
        return `${startPart}...`;
      }

    default:
      return originalString;
  }
};
