import { useLocation } from 'react-router-dom';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import qs from 'qs';
import { parseFilters } from '../utils';
import { FilterField, FilterRelation } from '..';

type ReturnedType = {
  filters: Record<string, unknown>;
  filterRelation: FilterRelation;
};

const useInitialFilters = (filterFields: Map<string, FilterField>): ReturnedType => {
  const location = useLocation();

  const {
    filters,
    filterRelation,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const parsedFilterRelation = (
    (filterRelation === FilterRelation.OR || filterRelation === FilterRelation.AND)
      ? filterRelation
      : undefined
  ) || FilterRelation.AND;

  if (_isString(filters)) {
    try {
      const parsedFilters: Record<string, string> = JSON.parse(filters);

      return {
        filters: _isObject(parsedFilters)
          ? parseFilters(filterFields, parsedFilters)
          : {},
        filterRelation: parsedFilterRelation,
      };
    } catch {
      return {
        filters: {},
        filterRelation: parsedFilterRelation,
      };
    }
  }

  return {
    filters: {},
    filterRelation: parsedFilterRelation,
  };
};

export default useInitialFilters;
